/**
 *
 * Youtube Player Modal
 * Author: jerrydomm
 */

let YouTubeMain = (function () {

    //Create DOM

    let Videoboxed = $('<div class="Video-box" data-modal="modal"><span class= "video-close"> <i class="far fa-times-circle"></i></span ><div class="Video-data"></div><a class="BtnStd BtnStd-primary videoLink"></a></div>');

    let disclaimerBoxed = $('<div class="video-disclaimer-box"></div >');
    let videoDomOverlay = $('<div>', {
        class: 'OverlayModal'
    });
    if ($('.icon-play, .videoSingle').length > 0) {
        $('main').append(Videoboxed, videoDomOverlay, disclaimerBoxed);
    }

    //DOM options
    let options = {
        videoTarget: 'videoTarget',
        videoMain: '.Video-box',
        videoContainer: '.Video-data',
        videoIdAttribute: 'videoId',
        videoDisclaimer: 'videoDisclaimer',
        videoCloseSelector: '.video-close',
        videoLink: 'videoLink',
        videoLinkText: 'videoLinkText'
    };

    //API
    function youTubeAPI() {
        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    //Init player
    let playerEl = document.querySelectorAll('[data-videoTarget]');
    youTubeAPI();
    for (let i = 0; i < playerEl.length; i++) {
        let playerAttr = $(playerEl[i]).attr('data-' + options.videoIdAttribute + ''),
            videoDisclaimer = $(playerEl[i]).attr('data-' + options.videoDisclaimer + ''),
            videoLink = $(playerEl[i]).attr('data-' + options.videoLink + ''),
            videoLinkText = $(playerEl[i]).attr('data-' + options.videoLinkText + ''),
            videoContainer = $(options.videoContainer);

        playerEl[i].addEventListener("click", function () {
            let thisPlayer = new YouTubePlayer(playerAttr, videoContainer);
            $(options.videoMain).addClass("showme");

            //disclaimer
            $(disclaimerBoxed).html(videoDisclaimer);

            if(typeof videoLink != 'undefined') {
                $('.'+options.videoLink).attr('href', videoLink);
                $('.'+options.videoLink).text(videoLinkText);
                $('.'+options.videoLink).html(videoLinkText);
            } else {
                $('.'+options.videoLink).addClass('d-none');
            }

        });

    }

    //player constructor

    function YouTubePlayer(sourceId, componentContainer) {

        this.id = 'idIframe_' + sourceId;
        this.sourceId = sourceId;
        this.playerObj = null;
        this.componentContainer = componentContainer;
        let self = this;
        let videoDomElement = $('<div>', {
            id: self.id
        });

        self.componentContainer.append(videoDomElement);

        self.playerObj = new YT.Player(self.id, {
            width: '100%',
            height: '100%',
            videoId: self.sourceId,
            playerVars: {
                'modestbranding': 1,
                'rel': 0,
                'showinfo': 0,
                'wmode': 'opaque',
                'iv_load_policy': 3,
                'autoplay': 1
            }
        });

    }

    // destroy player
    let playerClose = $(options.videoCloseSelector);
    playerClose.on('click', destroyVideo);

    function destroyVideo() {
        $(options.videoMain).removeClass('showme');
        let next = $(this).next().empty();
        $('main').remove('.OverlayModal');
    }

})();

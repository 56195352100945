window.Main = new (function () {

    var $windowWidth = $(window).width();
    var that = this;

    //Mobile functions
    let destructor = function(){
        $('.navSite').detach().appendTo('.DskNavBox');
        $('body').removeClass('noScroll');
    }

    let constructor = function(){
        $('.navSite').detach().prependTo('.tgNav');
    }

    this.matchesMenu = function(){
        let breackpoint = 1023,
            mql = window.matchMedia("(max-width:" + breackpoint + "px)");

        mediaqueryresponse(mql)

        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                constructor();
            } else {
                destructor();
            }
        }
    }

    //open-close menu
    this.menuToggle = function() {
        $('body').toggleClass('noScroll');
    }

    /**
     * Init
     */
    this.init = function () {

        //mobile menu
        that.matchesMenu();

        $('.hamburger').on('click', that.menuToggle);

        $('.tooltip').tooltip();
        //if($windowWidth < 991) {

            try {
                $('.slick-products').slick({
                    dots: true,
                    prevArrow: null,
                    nextArrow: null,
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 1399,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            } catch (e) {
                console.log(e);
            }


        //}

        //that.headerClass();

    };

    this.unSlick = function () {
        try {
            $('.slick-products').slick('unslick');
        } catch (e) {
            console.log(e);
        }

    };

    //add class to header on scroll
    this.headerClass = function(){
        $(window).scroll(function () {
            if ($(window).scrollTop() > 15) {
                $('header').addClass('stick')
            } else {
                $('header').removeClass('stick')
            }
            if( $('body').hasClass('is_team') ) {
                $('header').addClass('stick');
            }
        })
    }

})();

$(window).resize(function(){
    var $windowWidth = $(window).width();
    if ($windowWidth > 991) {
        //Main.unSlick();
    } else {
        //Main.init();
    }
});
